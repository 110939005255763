import { ref } from 'vue'

export const checkboxList: any = ref([
  {
    title: '付费情况',
    value: ['pay_purchase_sale_amount', 'pay_purchase_arpu'],
    indeterminate: true,
    checkAll: false,
    children: [
      {
        title: '销售额(USD)',
        dataIndex: 'pay_purchase_sale_amount',
        key: 'pay_purchase_sale_amount',
        sorter: true,
        width: 100,
        checked: false,
        desc: '成功支付的订单价格总和（美元） ',
      },
      {
        title: '销售额(CNY)',
        dataIndex: 'pay_purchase_sale_amount_cny',
        key: 'pay_purchase_sale_amount_cny',
        sorter: true,
        width: 100,
        checked: false,
        desc: '成功支付的订单价格总和（人民币）',
      },
      {
        title: '收入(USD)',
        dataIndex: 'pay_purchase_income_amount',
        key: 'pay_purchase_income_amount',
        sorter: true,
        width: 100,
        checked: false,
        desc: '平台分成后的实际收益（美元）',
      },
      {
        title: '收入(CNY)',
        dataIndex: 'pay_purchase_income_amount_cny',
        key: 'pay_purchase_income_amount_cny',
        sorter: true,
        width: 100,
        checked: false,
        desc: '平台分成后的实际收益（人民币）',
      },
      {
        title: '人均付费(USD)',
        dataIndex: 'pay_purchase_user_amount',
        key: 'pay_purchase_user_amount',
        sorter: true,
        width: 100,
        checked: false,
        desc: '平均每个付费用户贡献的订单金额（美元）',
      },
      {
        title: '人均付费(CNY)',
        dataIndex: 'pay_purchase_user_amount_cny',
        key: 'pay_purchase_user_amount_cny',
        sorter: true,
        width: 100,
        checked: false,
        desc: '平均每个付费用户贡献的订单金额（人民币）',
      },
      {
        title: '单次付费(USD)',
        dataIndex: 'pay_purchase_single_amount',
        key: 'pay_purchase_single_amount',
        sorter: true,
        width: 100,
        checked: false,
        desc: '平均每笔订单贡献的订单金额（美元）',
      },
      {
        title: '单次付费(CNY)',
        dataIndex: 'pay_purchase_single_amount_cny',
        key: 'pay_purchase_single_amount_cny',
        sorter: true,
        width: 100,
        checked: false,
        desc: '平均每笔订单贡献的订单金额（人民币）',
      },
      {
        title: '付费ARPU(USD)',
        dataIndex: 'pay_purchase_arpu',
        key: 'pay_purchase_arpu',
        sorter: true,
        width: 100,
        checked: false,
        desc: '平均每个活跃用户贡献的内购收入（美元）',
      },
      {
        title: '付费ARPU(CNY)',
        dataIndex: 'pay_purchase_arpu_cny',
        key: 'pay_purchase_arpu_cny',
        sorter: true,
        width: 100,
        checked: false,
        desc: '平均每个活跃用户贡献的内购收入（人民币）',
      },
      {
        title: '首次付费用户数',
        dataIndex: 'pay_purchase_first_user',
        key: 'pay_purchase_first_user',
        sorter: true,
        width: 100,
        checked: false,
        desc: '从首次启动至今(和筛选时间无关)，在当天且仅在当天累计付费次数为1的用户(累计付费次数为用户属性，该属性会随时间变化而变化)',
      },
      {
        title: '首次付费用户占比',
        dataIndex: 'pay_purchase_first_user_rate',
        key: 'pay_purchase_first_user_rate',
        sorter: true,
        width: 100,
        checked: false,
        desc: '首次付费用户数/所有付费用户数',
      },
      {
        title: '付费eCPM(USD)',
        dataIndex: 'pay_purchase_cpm',
        key: 'pay_purchase_cpm',
        sorter: true,
        width: 100,
        checked: false,
        desc: '每一千次展示可以获得的内购收入（美元）',
      },
      {
        title: '付费eCPM(CNY)',
        dataIndex: 'pay_purchase_cpm_cny',
        key: 'pay_purchase_cpm_cny',
        sorter: true,
        width: 100,
        checked: false,
        desc: '每一千次展示可以获得的内购收入（人民币）',
      },
    ],
  },
  {
    title: '用户',
    value: ['active_user', 'new_add_user', 'pay_purchase_active_rate', 'pay_purchase_new_active_rate'],
    indeterminate: true,
    checkAll: false,
    children: [
      {
        title: '活跃用户',
        dataIndex: 'active_user',
        key: 'active_user',
        sorter: false,
        width: 100,
        checked: false,
        desc: 'app登录用户数',
      },
      {
        title: '新增用户',
        dataIndex: 'new_add_user',
        key: 'new_add_user',
        sorter: false,
        width: 100,
        checked: false,
        desc: '活跃用户中，生命周期天数=1.0的用户数',
      },
      {
        title: '新增用户占比',
        dataIndex: 'new_add_user_rate',
        key: 'new_add_user_rate',
        sorter: false,
        width: 100,
        checked: false,
        desc: '新增用户/活跃用户',
      },
      {
        title: '新增付费用户',
        dataIndex: 'new_paid_user',
        key: 'new_paid_user',
        sorter: false,
        width: 100,
        checked: false,
        desc: '成功支付的用户中，生命周期天数=1的用户数',
      },
      {
        title: '活跃用户付费率',
        dataIndex: 'pay_purchase_active_rate',
        key: 'pay_purchase_active_rate',
        sorter: true,
        width: 100,
        checked: false,
        desc: '付费用户数/活跃用户数',
      },
      {
        title: '新增用户付费率',
        dataIndex: 'pay_purchase_new_active_rate',
        key: 'pay_purchase_new_active_rate',
        sorter: true,
        width: 100,
        checked: false,
        desc: '新增付费用户/新增用户',
      },
    ],
  },
  {
    title: '商品展示',
    value: [],
    indeterminate: false,
    checkAll: false,
    children: [
      {
        title: '商品展示次数',
        dataIndex: 'pay_show_times',
        key: 'pay_show_times',
        sorter: true,
        width: 140,
        checked: false,
        desc: '商品曝光次数',
      },
      {
        title: '商品展示用户数',
        dataIndex: 'pay_show_user',
        key: 'pay_show_user',
        sorter: true,
        width: 140,
        checked: false,
        desc: '商品曝光用户数',
      },
      {
        title: '商品展示人均次数',
        dataIndex: 'pay_show_user_per',
        key: 'pay_show_user_per',
        sorter: true,
        width: 140,
        checked: false,
        desc: '平均每个用户得到的商品曝光展示',
      },
    ],
  },
  {
    title: '商品点击',
    value: [],
    indeterminate: false,
    checkAll: false,
    children: [
      {
        title: '商品点击次数',
        dataIndex: 'pay_click_total',
        key: 'pay_click_total',
        sorter: true,
        width: 140,
        checked: false,
        desc: '点击购买按钮的总次数',
      },
      {
        title: '商品点击用户数',
        dataIndex: 'pay_click_user',
        key: 'pay_click_user',
        sorter: true,
        width: 140,
        checked: false,
        desc: '点击购买按钮的用户数（去重）',
      },
      {
        title: '商品点击人均次数',
        dataIndex: 'pay_click_user_per',
        key: 'pay_click_user_per',
        sorter: true,
        width: 140,
        checked: false,
        desc: '平均每个支付点击用户的点击次数',
      },
      {
        title: '商品点击率（次数）',
        dataIndex: 'pay_click_rate',
        key: 'pay_click_rate',
        sorter: true,
        width: 140,
        checked: false,
        desc: '商品点击次数/商品曝光次数',
      },
      {
        title: '商品点击率（用户数）',
        dataIndex: 'pay_click_user_rate',
        key: 'pay_click_user_rate',
        sorter: true,
        width: 140,
        checked: false,
        desc: '商品点击用户数/商品曝光用户数',
      },
    ],
  },
  {
    title: '支付成功',
    value: ['pay_purchase_total', 'pay_purchase_user'],
    indeterminate: true,
    checkAll: false,
    children: [
      {
        title: '总订单',
        dataIndex: 'pay_purchase_total',
        key: 'pay_purchase_total',
        sorter: true,
        width: 140,
        checked: false,
        desc: '成功支付的订单数',
      },
      {
        title: '付费用户数',
        dataIndex: 'pay_purchase_user',
        key: 'pay_purchase_user',
        sorter: true,
        width: 140,
        checked: false,
        desc: '成功支付的用户数(去重)',
      },
      {
        title: '人均付费次数',
        dataIndex: 'pay_purchase_user_per',
        key: 'pay_purchase_user_per',
        sorter: true,
        width: 140,
        checked: false,
        desc: '平均每个付费用户的付费次数',
      },
      {
        title: '商品支付成功率（次数）',
        dataIndex: 'pay_purchase_success_rate',
        key: 'pay_purchase_success_rate',
        sorter: true,
        width: 140,
        checked: false,
        desc: '支付成功次数/商品点击次数',
      },
      {
        title: '商品支付成功率（用户数）',
        dataIndex: 'pay_purchase_success_user_rate',
        key: 'pay_purchase_success_user_rate',
        sorter: true,
        width: 140,
        checked: false,
        desc: '支付成功用户数/商品点击用户数',
      },
    ],
  },
])
