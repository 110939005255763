import { ref, nextTick, onMounted } from 'vue'
// import { checkboxList } from "../data";
// type =1 默认是表头
// type =2 是导出
import _ from 'lodash'

export default function useCheckbox(emit, type = 1, list) {
  const showCheckList = ref([])
  const checkboxList = ref(_.cloneDeep(list))
  const visibleChange = () => {}
  const isCheckAll = ref(false)
  const isIndeterminate = ref(false)
  onMounted(() => {
    // if (localStorage.getItem('app_data_column')&& type==1) {
    //   checkboxList.value = JSON.parse(localStorage.getItem('app_data_column'))
    // }
    checkboxList.value.map((item) => {
      if (item.value.length == item.children.length) {
        item.checkAll = true
        item.indeterminate = false
      } else if (item.value.length == 0) {
        item.checkAll = false
        item.indeterminate = false
      } else {
        item.checkAll = item.checkAll
        item.indeterminate = item.indeterminate
      }
    })
    getShowList(true)
  })
  const onCheckAllChange = (e, group) => {
    console.log(e, group)
    if (!e.target.checked) {
      group.checkAll = false
      group.indeterminate = false
      group.value = []
    } else {
      group.checkAll = true
      group.indeterminate = false
      group.value = group.children.map((item) => item.key)
    }
    nextTick(() => {
      getShowList(e.target.checked)
    })
  }
  const getShowList = (tag) => {
    let temp = []
    checkboxList.value.forEach((item) => {
      item.value.forEach((checkOption) => {
        item.children.forEach((showOption) => {
          if (showOption.key == checkOption) {
            temp.push(showOption.title)
          }
        })
      })
    })
    showCheckList.value = temp
    if (!tag) {
      isCheckAll.value = false
      if (temp.length == 0) {
        isIndeterminate.value = false
      } else {
        isIndeterminate.value = true
      }
    } else {
      let arr = 0
      checkboxList.value.forEach((item) => {
        arr += item.children.length
      })
      if (showCheckList.value.length == arr) {
        isCheckAll.value = true
        isIndeterminate.value = false
      } else {
        isCheckAll.value = false
        isIndeterminate.value = true
      }
    }
    // type==1&& localStorage.setItem('app_data_column',JSON.stringify(checkboxList.value))
    emit('getColumn', { list: checkboxList.value, type })
  }
  const handleChange = (e, group) => {
    console.log(e.target.checked, group)
    if (group.children.length == group.value.length && e.target.checked) {
      group.indeterminate = false
      group.checkAll = true
    } else if (group.value.length == 1 && !e.target.checked) {
      group.indeterminate = false
      group.checkAll = false
    } else if (group.value.length == group.children.length - 1 && e.target.checked) {
      group.indeterminate = false
      group.checkAll = true
    } else if (group.value.length == group.children.length && !e.target.checked) {
      group.checkAll = false
      group.indeterminate = true
    } else {
      group.checkAll = false
      group.indeterminate = true
    }

    nextTick(() => {
      getShowList(e.target.checked)
    })
  }
  const handleAll = (e) => {
    let arr = [
      {
        title: '付费情况',
        value: [
          'pay_purchase_sale_amount',
          'pay_purchase_arpu',
          'pay_purchase_cpm_cny',
          'pay_purchase_cpm',
          'pay_purchase_first_user_rate',
          'pay_purchase_first_user',
          'pay_purchase_income_amount_cny',
          'pay_purchase_income_amount',
          'pay_purchase_new_active_rate',
          'pay_purchase_single_amount_cny',
          'pay_purchase_single_amount',
          'pay_purchase_user_amount_cny',
          'pay_purchase_user_amount',
          'pay_purchase_sale_amount_cny',
          'pay_purchase_arpu_cny',
        ],
        indeterminate: true,
        checkAll: false,
        children: [
          {
            title: '销售额(USD)',
            dataIndex: 'pay_purchase_sale_amount',
            key: 'pay_purchase_sale_amount',
            sorter: true,
            width: 100,
            checked: false,
            desc: '成功支付的订单价格总和（美元） ',
          },
          {
            title: '销售额(CNY)',
            dataIndex: 'pay_purchase_sale_amount_cny',
            key: 'pay_purchase_sale_amount_cny',
            sorter: true,
            width: 100,
            checked: false,
            desc: '成功支付的订单价格总和（人民币）',
          },
          {
            title: '收入(USD)',
            dataIndex: 'pay_purchase_income_amount',
            key: 'pay_purchase_income_amount',
            sorter: true,
            width: 100,
            checked: false,
            desc: '平台分成后的实际收益（美元）',
          },
          {
            title: '收入(CNY)',
            dataIndex: 'pay_purchase_income_amount_cny',
            key: 'pay_purchase_income_amount_cny',
            sorter: true,
            width: 100,
            checked: false,
            desc: '平台分成后的实际收益（人民币）',
          },
          {
            title: '人均付费(USD)',
            dataIndex: 'pay_purchase_user_amount',
            key: 'pay_purchase_user_amount',
            sorter: true,
            width: 100,
            checked: false,
            desc: '平均每个付费用户贡献的订单金额（美元）',
          },
          {
            title: '人均付费(CNY)',
            dataIndex: 'pay_purchase_user_amount_cny',
            key: 'pay_purchase_user_amount_cny',
            sorter: true,
            width: 100,
            checked: false,
            desc: '平均每个付费用户贡献的订单金额（人民币）',
          },
          {
            title: '单次付费(USD)',
            dataIndex: 'pay_purchase_single_amount',
            key: 'pay_purchase_single_amount',
            sorter: true,
            width: 100,
            checked: false,
            desc: '平均每笔订单贡献的订单金额（美元）',
          },
          {
            title: '单次付费(CNY)',
            dataIndex: 'pay_purchase_single_amount_cny',
            key: 'pay_purchase_single_amount_cny',
            sorter: true,
            width: 100,
            checked: false,
            desc: '平均每笔订单贡献的订单金额（人民币）',
          },
          {
            title: '付费ARPU(USD)',
            dataIndex: 'pay_purchase_arpu',
            key: 'pay_purchase_arpu',
            sorter: true,
            width: 100,
            checked: false,
            desc: '平均每个活跃用户贡献的内购收入（美元）',
          },
          {
            title: '付费ARPU(CNY)',
            dataIndex: 'pay_purchase_arpu_cny',
            key: 'pay_purchase_arpu_cny',
            sorter: true,
            width: 100,
            checked: false,
            desc: '平均每个活跃用户贡献的内购收入（人民币）',
          },
          {
            title: '首次付费用户数',
            dataIndex: 'pay_purchase_first_user',
            key: 'pay_purchase_first_user',
            sorter: true,
            width: 100,
            checked: false,
            desc: '生命周期内首次付费的用户',
          },
          {
            title: '首次付费用户占比',
            dataIndex: 'pay_purchase_first_user_rate',
            key: 'pay_purchase_first_user_rate',
            sorter: true,
            width: 100,
            checked: false,
            desc: '生命周期内首次付费的用户/所有付费用户',
          },
          {
            title: '付费eCPM(USD)',
            dataIndex: 'pay_purchase_cpm',
            key: 'pay_purchase_cpm',
            sorter: true,
            width: 100,
            checked: false,
            desc: '每一千次展示可以获得的内购收入（美元）',
          },
          {
            title: '付费eCPM(CNY)',
            dataIndex: 'pay_purchase_cpm_cny',
            key: 'pay_purchase_cpm_cny',
            sorter: true,
            width: 100,
            checked: false,
            desc: '每一千次展示可以获得的内购收入（人民币）',
          },
        ],
      },
      {
        title: '用户',
        value: [
          'active_user',
          'new_add_user',
          'pay_purchase_active_rate',
          'pay_purchase_new_active_rate',
          'new_paid_user',
          'new_add_user_rate'
        ],
        indeterminate: true,
        checkAll: false,
        children: [
          {
            title: '活跃用户',
            dataIndex: 'active_user',
            key: 'active_user',
            sorter: false,
            width: 100,
            checked: false,
            desc: 'app登录用户数',
          },
          {
            title: '新增用户',
            dataIndex: 'new_add_user',
            key: 'new_add_user',
            sorter: false,
            width: 100,
            checked: false,
            desc: '活跃用户中，生命周期天数=1.0的用户数',
          },
          {
            title: '新增用户占比',
            dataIndex: 'new_add_user_rate',
            key: 'new_add_user_rate',
            sorter: false,
            width: 100,
            checked: false,
            desc: '新增用户/活跃用户',
          },
          {
            title: '新增付费用户',
            dataIndex: 'new_paid_user',
            key: 'new_paid_user',
            sorter: false,
            width: 100,
            checked: false,
            desc: '成功支付的用户中，生命周期天数=1的用户数',
          },
          {
            title: '活跃用户付费率',
            dataIndex: 'pay_purchase_active_rate',
            key: 'pay_purchase_active_rate',
            sorter: true,
            width: 100,
            checked: false,
            desc: '付费用户数/活跃用户数',
          },
          {
            title: '新增用户付费率',
            dataIndex: 'pay_purchase_new_active_rate',
            key: 'pay_purchase_new_active_rate',
            sorter: true,
            width: 100,
            checked: false,
            desc: '新增付费用户/新增用户',
          },
        ],
      },
      {
        title: '商品展示',
        value: ['pay_show_times', 'pay_show_user', 'pay_show_user_per'],
        indeterminate: true,
        checkAll: false,
        children: [
          {
            title: '商品展示次数',
            dataIndex: 'pay_show_times',
            key: 'pay_show_times',
            sorter: true,
            width: 140,
            checked: false,
            desc: '商品曝光次数',
          },
          {
            title: '商品展示用户数',
            dataIndex: 'pay_show_user',
            key: 'pay_show_user',
            sorter: true,
            width: 140,
            checked: false,
            desc: '商品曝光用户数',
          },
          {
            title: '商品展示人均次数',
            dataIndex: 'pay_show_user_per',
            key: 'pay_show_user_per',
            sorter: true,
            width: 140,
            checked: false,
            desc: '平均每个用户得到的商品曝光展示',
          },
        ],
      },
      {
        title: '商品点击',
        value: [
          'firstBuyerCount',
          'firstBuyerRatio',
          'pay_click_total',
          'pay_click_user',
          'pay_click_user_per',
          'pay_click_rate',
          'pay_click_user_rate',
        ],
        indeterminate: true,
        checkAll: false,
        children: [
          {
            title: '商品点击次数',
            dataIndex: 'pay_click_total',
            key: 'pay_click_total',
            sorter: true,
            width: 140,
            checked: false,
            desc: '点击购买按钮的总次数',
          },
          {
            title: '商品点击用户数',
            dataIndex: 'pay_click_user',
            key: 'pay_click_user',
            sorter: true,
            width: 140,
            checked: false,
            desc: '点击购买按钮的用户数（去重）',
          },
          {
            title: '商品点击人均次数',
            dataIndex: 'pay_click_user_per',
            key: 'pay_click_user_per',
            sorter: true,
            width: 140,
            checked: false,
            desc: '平均每个支付点击用户的点击次数',
          },
          {
            title: '商品点击率（次数）',
            dataIndex: 'pay_click_rate',
            key: 'pay_click_rate',
            sorter: true,
            width: 140,
            checked: false,
            desc: '商品点击次数/商品曝光次数',
          },
          {
            title: '商品点击率（用户数）',
            dataIndex: 'pay_click_user_rate',
            key: 'pay_click_user_rate',
            sorter: true,
            width: 140,
            checked: false,
            desc: '商品点击用户数/商品曝光用户数',
          },
        ],
      },
      {
        title: '支付成功',
        value: [
          'pay_purchase_total',
          'pay_purchase_user',
          'pay_purchase_user_per',
          'pay_purchase_success_rate',
          'pay_purchase_success_user_rate',
        ],
        indeterminate: true,
        checkAll: false,
        children: [
          {
            title: '总订单',
            dataIndex: 'pay_purchase_total',
            key: 'pay_purchase_total',
            sorter: true,
            width: 140,
            checked: false,
            desc: '成功支付的订单数',
          },
          {
            title: '付费用户数',
            dataIndex: 'pay_purchase_user',
            key: 'pay_purchase_user',
            sorter: true,
            width: 140,
            checked: false,
            desc: '成功支付的用户数(去重)',
          },
          {
            title: '人均付费次数',
            dataIndex: 'pay_purchase_user_per',
            key: 'pay_purchase_user_per',
            sorter: true,
            width: 140,
            checked: false,
            desc: '平均每个付费用户的付费次数',
          },
          {
            title: '商品支付成功率（次数）',
            dataIndex: 'pay_purchase_success_rate',
            key: 'pay_purchase_success_rate',
            sorter: true,
            width: 140,
            checked: false,
            desc: '支付成功次数/商品点击次数',
          },
          {
            title: '商品支付成功率（用户数）',
            dataIndex: 'pay_purchase_success_user_rate',
            key: 'pay_purchase_success_user_rate',
            sorter: true,
            width: 140,
            checked: false,
            desc: '支付成功用户数/商品点击用户数',
          },
        ],
      },
    ]
    console.log(e.target.checked)
    isCheckAll.value = e.target.checked
    if (e.target.checked) {
      isIndeterminate.value = false
      let temp = []
      arr.forEach((item) => {
        item.checkAll = true
        item.indeterminate = false
        item.children.forEach((showOption) => {
          temp.push(showOption.title)
        })
      })
      showCheckList.value = temp
      checkboxList.value = arr
      emit('getColumn', { list: checkboxList.value, type })
    } else {
      isIndeterminate.value = false
      showCheckList.value = []
      checkboxList.value.forEach((item) => {
        item.value = []
        item.checkAll = false
        item.indeterminate = false
      })
      emit('getColumn', { list: checkboxList.value, type })
    }
  }
  return {
    showCheckList,
    isCheckAll,
    isIndeterminate,
    checkboxList,
    visibleChange,
    onCheckAllChange,
    getShowList,
    handleChange,
    handleAll,
  }
}
